import { render, staticRenderFns } from "./uploadCard.vue?vue&type=template&id=3bc07a6b&scoped=true"
import script from "./uploadCard.vue?vue&type=script&lang=js"
export * from "./uploadCard.vue?vue&type=script&lang=js"
import style0 from "./uploadCard.vue?vue&type=style&index=0&id=3bc07a6b&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc07a6b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3440258-cci-45622900-830175/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bc07a6b')) {
      api.createRecord('3bc07a6b', component.options)
    } else {
      api.reload('3bc07a6b', component.options)
    }
    module.hot.accept("./uploadCard.vue?vue&type=template&id=3bc07a6b&scoped=true", function () {
      api.rerender('3bc07a6b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/LawyerAuth/components/uploadCard.vue"
export default component.exports